import { useRef, useState } from 'react';
import './App.css';
import LandingPage from './LandingPage';
import GoogleOneTap from './components/GoogleOneTap';
import Matrix from './components/Matrix';

function App() {
  const [currentUser, setCurrentUser] = useState(true)
  const keyAccress = "the very beginning";
  const currentCode = useRef("")
  window.addEventListener("keyup", (eve) => {
    currentCode.current += eve.key
    if (currentCode.current === keyAccress) {
      setCurrentUser(!currentUser)
    }
    console.log(currentCode.current)
  })
  return (
    <>
      {currentUser ? <><GoogleOneTap currentUser={currentUser} setCurrentUser={setCurrentUser} />
        <LandingPage currentUser={currentUser} /></> : <Matrix />}
    </>
  );
}

export default App;
