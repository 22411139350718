import React, { useRef, useState } from "react";
import { images } from "./gallery";
import { FaBackward, FaGripHorizontal, FaTrashAlt } from "react-icons/fa";
import { addLink, initImages, shuffleArray } from "./firebaseauth";
import Scraper from "./Scraper";
import { FaA, FaShuffle } from "react-icons/fa6";
import ActionBar from "./components/ActionBar";
import AlbumsList from "./components/AlbumsList";
import ImageGrid from "./components/ImageGrid";
import PfpExamples from "./components/PfpExamples";
import ScrapePinImages from "./components/ScrapePinImages";
import ImageFilterCanvas from "./components/ImageFilterCanvas";
const LandingPage = ({ currentUser }) => {
  const [ArrImgUrl, uPdateArraImgUlr] = useState(shuffleArray(images));
  const [viewset, setViewSet] = useState(5);

  const handlviewset = (param) => {

    setViewSet(param)
  }

  function getRandomInRange(min, max, step) {
    const range = (max - min) / step;
    const randomStep = Math.floor(Math.random() * (range + 1));
    return min + randomStep * step;
  }
  const pageSc = {
    cols: viewset,
    rows: ArrImgUrl.length,
  };
  const arrMap = [];

  for (let index = 0; index < pageSc.cols; index++) {
    arrMap.push(index);
  }
  const newLink = useRef("");
  const [error, setError] = useState(null);

  const addMore = async () => {
    const Linkimg = newLink.current.value;
    const url = Linkimg.trim();
    if (url && isValidUrl(url)) {
      // Add image to the list or handle the URL submission
      console.log("Image URL added:", url);
      //uPdateArraImgUlr([url, ...ArrImgUrl]);
      addLink(url);
      setError(null);
      initImages(uPdateArraImgUlr)
    } else {
      setError("Please enter a valid URL.");
    }
  };
  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  };
  const newLinks = useRef("");

  const addMultiImages = () => {
    const Linkimg = newLinks.current.value;

    const urls = Linkimg.split(",");

    console.log(urls);

    const arr = [];
    urls.map((url) => {
      if (url && isValidUrl(url)) {
        arr.push(url);
        addLink(url);
        setError(null);
      } else {
        setError("Please enter a valid URL.");
      }
      return url
    });
    initImages(uPdateArraImgUlr, arr)

    /*
     */
  };
  const [view, setView] = useState(false);
  const [prevview, setPrevView] = useState();
  const [winpos, setWinpos] = useState({ x: 0, y: 0 });
  const [viewclose, setViewclose] = useState(false);
  const [album, setAlbumName] = useState(false);
  const [albumsList, setAlbumsList] = useState(false);

  console.log(prevview, viewclose)
  const indexRef = useRef();
  const handlIamgeView = (noHide) => {
    if (noHide) {
      setView(!view);
      indexRef.current = ArrImgUrl[getRandomInRange(0, ArrImgUrl.length, 1)];
    } else {
      setView(true);
      setIdlePick(ArrImgUrl[getRandomInRange(0, ArrImgUrl.length, 1)]);
      console.log(getRandomInRange(0, ArrImgUrl.length, 1))
    }
  };
  const shuffleAgain = () => {
    //const arr=shuffleArray(ArrImgUrl)
    //uPdateArraImgUlr(Array.from(arr));
    initImages(uPdateArraImgUlr)

  }
  const handlAlbumMod = () => {
    setAlbumName(!album)

  }
  const handlAlbumMods = () => {
    setAlbumsList(!albumsList)

  }

  const handlPrevPos = () => {
    setView(!view);
    window.scrollTo(winpos.x, winpos.y)
    console.log(winpos)
  }
  const [idlePick, setIdlePick] = useState(null);

  const [showuploader, setShowuploader] = useState(false);

  return (

    <div className="rounded-xl overflow-hidden">
      <ScrapePinImages />

      {/*<div className="absolute top-2 right-2 text-white z-50">
        <GoogleOAuthProvider clientId="49478870300-tk3rdqf0rdkhtcnn0pcmp2lvhtpkuhef.apps.googleusercontent.com" >
          
          <GoogleSignIn />
        </GoogleOAuthProvider>


      </div>*/}
      {view && (
        <>
          <PfpExamples src={indexRef.current || idlePick} size={9} />
          <div className="container mx-auto bg-gray-300 dark:bg-gray-900 z-50 top-0 mt-8 flex items-center justify-center">

            <img
              className="rounded-lg"
              src={indexRef.current || idlePick}
              alt={indexRef.current || idlePick}
              style={{ maxWidth: "500px" }}
            />
            <span
              onClick={() => { handlPrevPos() }}
              className="absolute w-12 h-12 rounded-full hover:bg-red-500 cursor-pointer sm:top-4 left-4 flex items-center justify-center text-gray-500 dark:text-white"
            >
              <FaBackward />
            </span>

            <button
              className="absolute bottom-80 right-4 w-32 h-32 z-50 opacity-30 hover:opacity-100 text-white text-4xl flex items-center justify-center ml-2 bg-indigo-500 hover:bg-indigo-700 space-x-2 px-3 py-1 rounded-full focus:outline-none"
            >
            </button>
            <div
              onClick={() => {
                handlIamgeView(false);
              }}

              class="absolute bottom-80 right-8 rounded-full w-24 h-24 bg-indigo-500 opacity-50 z-50 flex items-center justify-center text-4xl text-white">
              <FaShuffle />
            </div>
            <div class="absolute bottom-80 right-6 rounded-full w-28 h-28 bg-indigo-300 opacity-30 z-10"></div>

          </div>
        </>

      )}
      <div className="container mx-auto w-screen p-5 h-fit bg-gray-900" id="top">
        <div className="text-center text-white m-2">
          <h1 className="text-4xl font-bold mb-5">Idk What To Name This App</h1>
          <code className="bg-gray-800 text-green-400 p-2 rounded-lg">{'`are-we-there-yet`'}</code>
        </div>
        {album && <ActionBar />}
        {albumsList && <AlbumsList setAlbumArr={uPdateArraImgUlr} />}
        <div className="fixed bottom-4 right-4 opacity-50 hover:opacity-100 z-50">
          <a href="#top">
            <span className="bg-orange-500 w-10 h10 rounded p-2 cursor-pointer hover:text-white">UP</span>
          </a>
        </div>

        <div className="relative h-32  rounded bg-gray-800">
          <Scraper onClose={(param) => { setViewclose(param) }} />
        </div>
        <div className="relative h-fit mb-2" >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 h-fit">
            <div className="relative">
              <div className="bg-orange-500 w-full mt-2 rounded h-14 flex items-center justify-between p-3">
                <h3 className="text-white font-bold text-lg" >
                  Gallery Pin Collection - ({ArrImgUrl.length})
                </h3>



              </div>
              <div className="flex mt-4 w-full">
                <button
                  onClick={() => {
                    handlAlbumMods();
                  }}
                  className="text-white ml-2 bg-orange-600 hover:bg-orange-700 px-3 py-1 rounded focus:outline-none"
                >
                  {albumsList ? 'Cancel' : `View Albums List`}
                </button>

                <button
                  onClick={() => {
                    handlAlbumMod();
                  }}
                  className="text-white ml-2 bg-orange-600 hover:bg-orange-700 px-3 py-1 rounded focus:outline-none"
                >
                  {album ? 'Cancel' : `Create New Album`}
                </button>



                <button
                  onClick={() => {
                    handlIamgeView();
                  }}
                  className="text-white ml-2 bg-orange-600 hover:bg-orange-700 px-3 py-1 rounded focus:outline-none"
                >
                  {`Get a Pfp (Image)`}
                </button>
              </div>
              <div className="">
                <h4 className="text-white p-2">Recent photos</h4>
                <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-8 gap-0">
                  {ArrImgUrl.map((src, index) => (
                    index < 5 && <img className="rounded" key={index} src={ArrImgUrl[ArrImgUrl.length - (index + 1)]} alt="" style={{ maxWidth: "80px" }} />
                  ))}
                </div>

              </div>
              <div className="flex relative top-8 left-0">
                <button
                  onClick={(e) => { e.preventDefault(); shuffleAgain() }}
                  className="ml-5 text-indigo-500 w-12 h-12 p-2 rounded-full hover:bg-gray-500 hover:text-white flex justify-center items-center ">
                  <FaShuffle />
                </button>
                <button
                  onClick={() => { handlviewset(8) }}
                  className=" ml-5 text-indigo-500 w-12 h-12 p-1 rounded-full hover:bg-gray-500 hover:text-white flex justify-center items-center ">
                  <FaGripHorizontal />
                </button>
                <button
                  onClick={() => { handlviewset(4) }}
                  className=" ml-5 text-indigo-500 w-12 h-12 p-1 rounded-full hover:bg-gray-500 hover:text-white flex justify-center items-center ">
                  <FaA />
                </button>
                <button
                  onClick={() => { handlviewset(4) }}
                  className="ml-5 text-indigo-500 w-12 h-12 p-1 rounded-full hover:bg-gray-500 hover:text-white flex justify-center items-center ">
                  <FaTrashAlt />
                </button>
              </div>

            </div>


            <div className="p-4 rounded-lg mt-2">
              <button
                className="m-2 float-right text-indigo-500  p-1 hover:bg-gray-500 hover:text-white flex justify-center items-center "
                onClick={() => { setShowuploader(!showuploader) }}> {showuploader ? 'Cancel' : 'Upload image'} </button>
              <div className={showuploader ? '' : 'hidden'}>
                <input
                  ref={newLink}
                  type="text"
                  placeholder="http://imageUrl.jpg"
                  className="border border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <button
                  className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-700"
                  onClick={addMore}
                >
                  Add Image
                </button>
                <hr className="my-2" />
                {error && <p className="text-red-500 mt-2">{error}</p>}
                <textarea
                  ref={newLinks}
                  type="text"

                  style={{ maxHeight: "200px", height: "150px" }}
                  placeholder={`http://imageUrl01.jpg,\nhttp://imageUrl02.jpg,\nhttp://imageUrl01.jpg,\nhttp://imageUrl03.jpg`}
                  className="border border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <button
                  className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-700"
                  onClick={addMultiImages}
                >
                  Add Multiple Images
                </button>
                {error && <p className="text-red-500 mt-2">{error}</p>}

              </div>
            </div>
          </div>
        </div>
        {view && <ImageFilterCanvas src={indexRef.current || idlePick}  style={`relative flex flex-col justify-center items-center`}/>}
        <ImageGrid ArrImgUrl={ArrImgUrl} pageSc={pageSc} viewImage={setView} indexRef={setIdlePick} PrevView={setPrevView} winpos={setWinpos} />

      </div>
    </div>

  );
};

export default LandingPage;
